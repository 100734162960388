<template>
  <HomeComponent/>
  <MemberComponent/>
  <img id="s-projects-background" src="/assets/img/wave (7).svg">
  <AboutComponent/>
  <ProjectComponent/>
  <img id="s-projects-background" src="/assets/img/wave (9).svg">
  <EmphasisComponent/>
</template>

<script>
import HomeComponent     from "@/components/HomeComponent.vue";
import MemberComponent   from "@/components/MemberComponent.vue";
import ProjectComponent  from "@/components/ProjectComponent.vue";
import AboutComponent    from "@/components/AboutComponent.vue";
import EmphasisComponent from "@/components/EmphasisComponent.vue";

export default {
  name: 'HomeView',
  components: {
    ProjectComponent,
    HomeComponent,
    MemberComponent,
    AboutComponent,
    EmphasisComponent
  }
}
</script>

<style></style>
