<template>
  <section id="s-footer">
    <div class="grid-layout">
      <div id="footer">
        <article class="sections-footer intervalCardReveal">
          <div class="section-block">
            <p>Segurança</p>
            <ul>
              <li>
                <a href="/sections/privacy/regulation">Regulamento, privacidade e termos</a>
              </li>
              <li>
                <a href="/sections/problems/contact">Reportar problema de segurança</a>
              </li>
            </ul>
          </div>
          <div class="section-block">
            <p>Outros</p>
            <ul>
              <li>
                <a href="https://news.orgdestiny.com">Novidades</a>
              </li>
              <li>
                <a href="https://nets.orgdestiny.com">Conexões com o mundo</a>
              </li>
            </ul>
          </div>
          <div class="section-block">
            <p>Entre em contato</p>
            <ul>
              <li>
                <a href="/contact">Contate Destiny</a>
              </li>
              <li>
                <a href="/structure">Estrutura Organizacional</a>
              </li>
              <li>
                <a href="/maps">Mapas e direções</a>
              </li>
              <li>
                <a href="/contributes">Contribua</a>
              </li>
            </ul>
          </div>
        </article>
        <article class="sections-footer-bottom intervalCardReveal">
          <div id="copyright-footer">
            <p>&copy; <span>{{ fullYear }}</span> Comando Administrativo & Portaria com membros de respectivos campos Destiny</p>
          </div>
          <div class="logo-footer">
            <img src="/assets/img/logo-destiny.svg" alt="Destiny" loading="lazy" />
          </div>
          <div class="socials-footer">
            <ul>
              <li><text>Siga Destiny</text></li>
              <li>
                <a href="https://github.com/orgdestiny"><svg><use xlink:href="#icon-907"></use></svg></a>
              </li>
              <li>
                <a href="https://twitter.com/org__destiny"><svg><use xlink:href="#icon-90e"></use></svg></a>
              </li>
              <li>
                <a href="https://discord.com/invite/daBWqkRuf9"><svg><use xlink:href="#icon-90c"></use></svg></a>
              </li>
            </ul>
          </div>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
 export default {
   name: 'FooterComponent',
   data() {
     return {
       fullYear: new Date().getFullYear()
     }
   }
 }
</script>

<style></style>
