<template>
  <section id="s-home">
    <div class="grid-layout">
      <div id="home">
        <div id="home-container-text">
          <h1 align="center" style="font-size: 1.5em; opacity: 0.80; color: #A3A0AC; font-weight: inherit; line-height: 0;">
            COMPANHIA BRASILERA
          </h1>
          <h1 align="center" style="font-family: 'Steelfish'; font-weight: 900; font-size: 13rem; " id="textHome" class="delayMediumReveal"> DESTINY</h1>
          <br />
          <h1 align="center" style="font-weight: 500" class="delayMediumReveal">
            Intercedemos que, voáreis com precisão; alcançáreis ao vastidão. Desejo. Conquiste com este novo destino.
          </h1>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'HomeComponent'
  }
</script>

<style></style>
