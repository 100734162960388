<template>
  <div class="go-to-top" id="buttonTop">
    <img src="/assets/img/up-arrow.svg">
  </div>
</template>

<script>
  export default {
    name: 'GoToTopComponent',
    mounted() {
      window.addEventListener('load', () => {
        const aboutElement = document.querySelector('#s-home');
        const stylesAbout = window.getComputedStyle(aboutElement);
        const buttonTop = document.querySelector('#buttonTop');
        const heightAboout = stylesAbout.height.split('px')[0];

        buttonTop.addEventListener('click', () => {
          window.scrollTo(0, 0);
        });

        window.addEventListener('scroll', () => {
          const isMov = window.scrollY > (heightAboout / 2);

          if (isMov) {
            buttonTop.style.opacity = 1;
          } else {
            buttonTop.style.opacity = 0;
          }
        });
      });
    }
  }
</script>

<style></style>
