<template>
  <section id="s-about">
    <div class="grid-layout">
      <div id="about">
        <article id="about-text">
          <h2 class="delaySmallReveal">Conheça <span>.</span>
          </h2>
          <p class="delayMediumReveal"> Somos uma companhia de primeira, formada pelos pilares de cooperação, projetos, educação e confraternização. A Destiny é a matriz que reúne todas as nossas instâncias.</p>
          <p class="delayMediumReveal"> Estamos aqui para expressarmos e formarmos nossas opiniões e explorar o conhecimento humano; dedicamos especial atenção à formação ética e moral dos membros, temos o intuito de aprimorar o educando como pessoa humana, tendo o desenvolvimento da autonomia intelectual e do pensamento crítico através do diálogo e do respeito à diversidade cultural. Vamos juntos nessa, com Destiny!</p>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutComponent'
}
</script>

<style></style>