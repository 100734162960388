<template>
  <section id="s-destact">
    <div class="grid-layout">
      <div id="about">
        <article id="about-text">
          <h1 class="delaySmallReveal">9
          </h1>
          <h2 class="delaySmallReveal">Venha estabelecer o melhor para você <span>.</span>
          </h2>
          <p class="delayMediumReveal"> A Companhia estabelece um trabalho cooperativo para todos, <font style="font-weight: 700; color: #9e86fd;">junte-se a nós! Para um futuro melhor.</font></p>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'EmphasisComponent'
  }
</script>

<style></style>
