<template>
  <section id="s-projects">
    <div class="grid-layout">
      <div id="about-blog">
        <article id="about-text">
          <div class="ss-aa">
            <p style="margin-left: 10px; text-align: left;" class="delayMediumReveal"> Abrindo este projeto, encontramos portas para descobrimento e oportunidades nas possíveis áreas de trabalho e comunicações, a partir do desenvolvimento habitual da comunidade e o mundo. Ocorrendo a origem de contribuições e auxílios a partir desta nova comunidade, podemos estar relacionando a todas as atividades extraescolares, escolares e tanto as quais que praticamos dentro de nossos setores. Disponibilizando e aplicando assim, um fórum de artigos científicos, ou de quaisquer categorias, ou alheio ao ambiente de Destiny. Com a fundamental participação e colaboração da respectiva Comunidade Escolar, Empresarial e Externa, com suporte técnico do Comando Administrativo da companhia diretamente aos consumidores. </p>
            <div class="ss-aa-bb">
              <tbutton style="background: url('https://t4.ftcdn.net/jpg/02/65/59/53/360_F_265595302_LlycYHDhzQ7L9QZTdGx8UmVWoSoXHwsS.jpg'); background-size: cover;" class="delayMediumReveal">
                <div class="ubtn">
                  <p style="text-align: left; font-weight: 500; margin-left: 20px;" class="delayMediumReveal"> Leia nossos artigos</p>
                  <p style="text-align: left; font-size: 17px; margin-left: 20px;" class="delayMediumReveal"> Nossos membros estarão postando artigos cíentificos desenvolvidos por tais.</p>
                  <pptext>Saiba mais
                    <svg>
                      <use xlink:href="#icon-90p"></use>
                    </svg>
                  </pptext>
                </div>
              </tbutton>
              <tbutton style="background: url('https://images.pexels.com/photos/7130475/pexels-photo-7130475.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'); background-size: cover;" class="delayMediumReveal">
                <div class="ubtn">
                  <p style="text-align: left; font-weight: 500; margin-left: 20px;" class="delayMediumReveal"> Saiba de nossos projetos</p>
                  <p style="text-align: left; font-size: 17px; margin-left: 20px;" class="delayMediumReveal"> Projetos desenvolvidos para a comunidade extraescolar e escolar.</p>
                  <pptext>Saiba mais
                    <svg>
                      <use xlink:href="#icon-90p"></use>
                    </svg>
                  </pptext>
                </div>
              </tbutton>
            </div>
          </div>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'ProjectComponent'
  }
</script>

<style></style>
