<template>
  <header>
    <div class="grid-layout">
      <nav>
        <div class="mobile-menu">
          <div class="line1"></div>
          <div class="line2"></div>
          <div class="line3"></div>
        </div>

        <ul class="nav-list">
          <!-- TODO: consertar imagem -->
          <li>
            <router-link to="/"><img alt="" itemprop="logo" class="c-image" src="https://cdn.discordapp.com/attachments/978431802848997437/1028335406405648484/destinoskd.png" role="presentation" aria-hidden="true" style="overflow-x: visible;"></router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Home', hash: '#s-about' }">Informações</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Home', hash: '#s-projects' }">Projetos</router-link>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderComponent',
  mounted() {
    window.addEventListener('load', () => {
      const menuElement = document.querySelector('nav');
      const stylesMenu = getComputedStyle(menuElement);
      const heightMenu = stylesMenu.height.split('px')[0];
      const headerElement = document.querySelector('header');

      window.addEventListener('scroll', () => {
        const isMov = window.scrollY > heightMenu ? true : false;

        if (isMov) {
          headerElement.style.backdropFilter = 'blur(5px)';
        } else if (headerElement.classList.contains('blur')) {
          headerElement.style.backdropFilter = 'blur(5px)';
        } else {
          headerElement.style.backdropFilter = 'none';
        }

      });
    });

    const menu = () => {
      class MobileNavbar {
        constructor(mobileMenu, navList, navLinks, navLinks2) {
          this.mobileMenu = document.querySelector(mobileMenu);
          this.navList = document.querySelector(navList);
          this.navLinks = document.querySelectorAll(navLinks);
          this.navLinks2 = document.querySelectorAll(navLinks2);
          this.header = document.querySelector('header');
          this.activeClass = "active";
          this.activeClassHeader = "blur"
          this.handleClick = this.handleClick.bind(this);
        }

        preventScroll(e) {
          e.preventDefault();
          e.stopPropagation();

          return false;
        }

        animateLinks() {
          this.navLinks.forEach((link, index) => {
            link.style.animation ?
                (link.style.animation = "") :
                (link.style.animation = `navLinkFade 0.5s ease forwards ${
                    index / 7 + 0.3
                }s`);
          });
        }

        handleClick() {
          this.navList.classList.toggle(this.activeClass);
          this.mobileMenu.classList.toggle(this.activeClass);

          this.animateLinks();
        }

        addClickEvent() {
          this.mobileMenu.addEventListener("click", this.handleClick);
          this.navLinks2.forEach((item) => {
            item.addEventListener("click", this.handleClick);
          });
        }

        init() {
          if (this.mobileMenu) {
            this.addClickEvent();

          }
          return this;
        }
      }
      const mobileNavbar = new MobileNavbar(
          ".mobile-menu",
          ".nav-list",
          ".nav-list li",
          ".nav-list li a"
      );
      mobileNavbar.init();
    }

    menu();
  }
}
</script>

<style></style>
