<template>
  <section id="s-members">
    <div class="grid-layout">
      <h2 class="delaySmallReveal">Membros <span>.</span>
      </h2>
      <div class="members-base delayMediumReveal"></div>
    </div>
  </section>
</template>

<script>
  import axios from "axios";

  export default {
    name: 'MemberComponent',
    async mounted() {
      $(document).ready(function(){
        $('.members-base').slick({
          centerMode: true,
          centerPadding: '60px',
          slidesToShow: 1,
          variableWidth: true,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 600,
          draggable: true,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 1
              }
            }
          ]
        });
      });

      const auth = await axios.post('https://api.orgdestiny.com/auth', {
        username: process.env.VUE_APP_USER_API,
        password: process.env.VUE_APP_PASSWORD_API
      });

      axios.get('https://api.orgdestiny.com/user', {
        headers: {
          "Authorization": auth.data.token
        }
      }).then(resp => {
        const designers = resp.data.designers;
        let designersModificados = []
        designers.forEach(designer => {
          designer.multipleRole = false;
          designersModificados.push(designer);
        });

        const desenvolvedores = resp.data.developers;
        let desenvolvedoresModificados = [];
        desenvolvedores.forEach(dev => {
          dev.multipleRole = false;
          desenvolvedoresModificados.push(dev);
        });

        let members = [];

        designers.forEach(designer => {
          const findMemberEqual = desenvolvedores.find(member => {
            return member.user.id === designer.user.id;
          });

          if (findMemberEqual) designer.multipleRole = true;

          if (designer.multipleRole) {
            designer.role = 'Designer & Desenvolvedor'
          } else {
            designer.role = 'Designer'
          }

          members.push(designer);
        });

        desenvolvedores.forEach(dev => {
          const findMemberEqual = designers.find(member => {
            return member.user.id === dev.user.id;
          });

          if (!findMemberEqual) {
            dev.role = 'Desenvolvedor';
            members.push(dev);
          }
        });

        let memberSort = members.sort((a, b) => {
          if (a.user.username < b.user.username) return -1;
          if (a.user.username > b.user.username) return 1;
          return 0;
        });

        const globalNameTag = (globalName) => {
          return `<p id="cargo-membros"><span class="material-icons">badge</span> ${globalName}</p>`;
        }

        const avatarDecoration = (decorationHash) => {
          if (decorationHash === '' || decorationHash === null) return '';

          return '<img style="position: absolute;" src="https://cdn.discordapp.com/avatar-decoration-presets/' + decorationHash.asset + '.png?size=96&passthrough=true" alt="Imagem Padrão" />'
        }

        memberSort.forEach(member => {
          $('.members-base').slick('slickAdd', `
    <div class="member-area" style="position: relative;"> <!-- Adicionado estilo position: relative; -->
      <div class="member-image" style="position: relative">
        ${avatarDecoration(member.user.avatar_decoration_data)}
        <img src="https://cdn.discordapp.com/avatars/${member.user.id}/${member.user.avatar}" alt="Imagem Padrão" />
      </div>
      <div class="member-info">
        <p id="nome-membros"><img src="/assets/img/discord.svg"  alt="Discord"/> ${member.user.username}</p>
        ${member.user.global_name !== undefined && member.user.global_name !== null ? globalNameTag(member.user.global_name) : '' }
        <p id="cargo-membros"><span class="material-icons">groups</span> ${member.role}</p>
      </div>
    </div>
  `);
        });
      });
    }
  }
</script>

<style></style>
