<template>
  <SvgComponent/>
  <HeaderComponent/>
  <main>
    <router-view/>
    <FooterComponent/>
  </main>
  <GoToTopComponent/>
</template>

<script>
import HeaderComponent  from "@/components/HeaderComponent.vue";
import FooterComponent  from "@/components/FooterComponent.vue";
import SvgComponent     from "@/components/SvgComponent.vue";
import GoToTopComponent from "@/components/GoToTopComponent.vue";

export default {
  name: 'DestinyComponent',
  components: {
    HeaderComponent,
    FooterComponent,
    SvgComponent,
    GoToTopComponent
  },
  mounted() {
    document.addEventListener('DOMContentLoaded', function () {
      const sr = ScrollReveal({
        origin: "top",
        distance: "50px",
        duration: 2000
      });

      sr.reveal(".delaySmallReveal", { delay: 200 });
      sr.reveal(".delayMediumReveal", { delay: 300 });
      sr.reveal(".delayLargeReveal", { delay: 400 });
      sr.reveal(".delayExtraBigReveal", { delay: 600 });
      sr.reveal(".intervalCardReveal", { interval: 400 });
    });

    (() => {
      let changer = setInterval(() => {
        let colors = [
          "rgb(77, 166, 255)",
          "rgb(0, 115, 230)",
          "rgb(0, 77, 153)",
          "rgb(0, 64, 128)",
          "rgb(0, 38, 77)",
          "rgb(0, 0, 0)"
        ];

        let randomItem = colors[Math.floor(Math.random() * colors.length)];
        let elementColor = document.getElementById("s-FtVqq");
        if (elementColor != null) {
          return elementColor.style.color = randomItem;
        }

        return clearTimeout(changer);
      }, 900)
    })();
  }
}
</script>

<style></style>
